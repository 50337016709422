import * as React from "react";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import {footerMenu, website} from "../data/Constant";

export default function Footer() {
    return (
        <React.Fragment>
            <Container maxWidth="lg" component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">
                    {footerMenu.map((menu) => (
                        <Grid item xs={6} sm={3}>
                            <Typography variant="h6" color="text.primary" gutterBottom children={menu.title} />
                            <ul>
                                {menu.items.map((item) => (
                                    <li>
                                        <Link href={item.href} target="_blank" variant="subtitle1" color="text.secondary">
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Copyright position="sticky" sx={{ mt: 5 }} />
            </Container>
        </React.Fragment>
    );
}

function Copyright(props) {
    return (
        <React.Fragment>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Todos os direitos reservados ® '}
                <Link color="inherit" href={website.address}>
                    {website.name}
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </React.Fragment>
    );
}