import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {services} from "../data/Constant";

export default function ServiceCards() {
    return (
        <React.Fragment>
            <Container className='content' maxWidth="lg">
                <Typography variant="h3" align="center"
                            color="text.primary" gutterBottom>
                    Serviços
                </Typography>
                <Grid container alignItems="center" justifyContent="space-between">
                    {services.map((service) => (
                        <Card sx={{
                            width: 345,
                            my: 1,}} >
                            <CardHeader
                                title={service.title}
                                subheader={service.subtitle}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                // width="345"
                                w
                                image={service.image}
                                alt={service.title}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {service.descritption}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    );
}