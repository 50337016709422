import * as React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function Hero({hero}) {
    return (
        <React.Fragment>
            <Container className='content' maxWidth="sm">
                <Typography children={hero.title} variant="h3" align="center" color="text.primary" gutterBottom/>
                <Typography children={hero.description} variant="h6" align="center" color="text.secondary"/>
            </Container>
        </React.Fragment>
    );
}