import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {green} from "@mui/material/colors";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import SvgComponent from "./SvgComponent";


import {contacts, navMenu} from "../data/Constant";

export default function Nav() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar position="sticky" color="default" elevation={4}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>

                        <ButtonGroup sx={{flexBasis: '33.33%', display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                                children={<MenuIcon/>}
                            />

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                                keepMounted
                                transformOrigin={{vertical: 'top', horizontal: 'left',}}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{display: {xs: 'block', md: 'none'},}}
                            >
                                {navMenu.map((menu) => (
                                    <ListItemButton href={menu.href}>
                                        <Typography children={menu.label} textAlign="center"/>
                                    </ListItemButton>
                                ))}
                            </Menu>
                        </ButtonGroup>

                        <ButtonGroup variant="text" aria-label="text button group"
                            sx={{flexBasis: '33.33%', display: {xs: 'none', md: 'flex'}}}>
                            {navMenu.map((menu) => (
                                <Button href={menu.href} children={menu.label}/>
                            ))}
                        </ButtonGroup>

                        <SvgComponent/>

                        <ButtonGroup sx={{flexBasis: '33.33%', justifyContent: 'right'}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="contact-bar"
                                aria-haspopup="true"
                                onClick={handleOpenUserMenu}
                                color="inherit"
                                children={<WhatsAppIcon sx={{color: green[500]}}/>}
                            />

                            <Menu
                                id="contact-bar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                                keepMounted
                                transformOrigin={{vertical: 'top', horizontal: 'right',}}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                // sx={{display: {xs: 'block', md: 'none'},}}
                            >
                                {contacts.map((contact) => (
                                    <ListItemButton href={'https://wa.me/' + contact.number} target="_blank">
                                        <Typography children={contact.name} textAlign="center"/>
                                    </ListItemButton>
                                ))}
                            </Menu>
                        </ButtonGroup>

                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    )
}