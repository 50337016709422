export const comments = [
    {
        name: 'Bruno R.',
        avatar: '/static/images/avatar/1.jpg',
        subject: 'Detalhismo',
        message: 'Estou muito impressionado com o conhecimento técnico e a atenção aos detalhes que vêm auxiliando a minha construtora desde a abertura.',
    },
    {
        name: 'Diego P.',
        avatar: '/static/images/avatar/2.jpg',
        subject: 'Especialistas em IRPF',
        message: 'Recomendo fortemente! Foram precisos na solução de malha fiscal do imposto de renda.',
    },
    {
        name: 'Edna F.',
        avatar: '/static/images/avatar/3.jpg',
        subject: 'Tradição',
        message: 'A Griffo administra o condomínio onde moro desde 1995 e nossas demandas são sempre atendidas com muito profissionalismo.',
    },
]
export const contacts = [
    {
        name: 'Comercial',
        mail: 'querosercliente@grupogriffo.com',
        number: '551332782830',
    },
    {
        name: 'Condomínios',
        mail: 'condominios@grupogriffo.com',
        number: '551332782830',
    },
    {
        name: 'Fiscal e Contábil',
        mail: 'fiscal@grupogriffo.com',
        number: '551332782831',
    },
    {
        name: 'RH',
        mail: 'rh@grupogriffo.com',
        number: '551332782832',
    },
]
export const footerMenu = [
    {
        title: 'Nossa redes',
        items: [
            {
                name: 'Facebook',
                href: 'https://www.facebook.com/GriffoSantos',
            },
            {
                name: 'LinkedIn',
                href: 'https://www.linkedin.com/company/GriffoGrupo',
            },
        ],
    },
    {
        title: 'Trabalhe conosco',
        items: [
            {
                name: 'Envio de currículo',
                href: 'mailto: recrutamento@grupogriffo.com',
            },
        ],
    },
    {
        title: 'Área do cliente',
        items: [
            {
                name: 'Boleto de condomínio',
                href: 'https://www.boletos.grupogriffo.com/',
            },
        ],
    },
]
export const heros = [
    {
        title: 'Indispensável',
        description: 'Empresas e condomínios estão sujeitos a diversas obrigações perante orgãos públicos, sendo que a maioria delas é de origem trabalhista, fiscal e contábil.',
    },
    {
        title: 'Quem somos',
        description: 'Fundado em 1992, prestamos serviços essenciais de contabilidade para empresas e condomínios, realizando, também, consultoria e auditoria.',
    },
    {
        title: 'Nossa missão',
        description: 'Solucionar as dificuldades dos nossos clientes de maneira satisfatória, respeitando valores éticos e buscando a prosperidade de todos os envolvidos, desde fornecedores a clientes.',
    },
    {
        title: 'Incentivo a conformidade',
        description: 'Acreditamos que a conformidade inspira confiança para realização de grandes negócios.',
    },
    {
        title: 'Contabilidade generalista',
        description: 'Nossa equipe multidisciplinar é capaz de desempenhar as rotinas dos diversos regimes tributários com excelência.',
    },
]
export const navMenu = [
    {
        label: 'Home',
        href: '#home',
    },
    {
        label: 'Serviços',
        href: '#servicos',
    },
    {
        label: 'Contato',
        href: '#contato',
    },
]
export const services = [
    {
        title: 'Contabilidade',
        subtitle: 'Para empresas e condomínios',
        descritption: 'Demonstrações contabéis com conformidade legal.',
        image: "/static/images/cards/contabilidade.jpeg",
    },
    {
        title: 'Folha de pagamento',
        subtitle: 'Para pessoas físicas, empresas e condomínios',
        descritption: 'Escrituração das obrigações trabalhistas e previdenciárias.',
        image: "/static/images/cards/cracha.webp",
    },
    {
        title: 'Rateio e cobrança',
        subtitle: 'Para condomínios',
        descritption: 'Classificação de despesas por centro de custos, arrecadação e emissão de boletos.',
        image: "/static/images/cards/rateio-cobranca.webp",
    },
    {
        title: 'Escrituração fiscal',
        subtitle: 'Para empresas e condomínios',
        descritption: 'Registros de faturamento, serviços tomados e compras.',
        image: "/static/images/cards/livros.jpeg",
    },
    {
        title: 'Contas a pagar',
        subtitle: 'Para condomínios',
        descritption: 'Análise de conformidade, autorização, agendamento e liquidação bancária.',
        image: "/static/images/cards/contas-pagar.webp",
    },
    {
        title: 'Imposto de renda',
        subtitle: 'Para pessoas físicas',
        descritption: 'Declarações de ajuste anual, ganho de capital, livro caixa e final de espólio.',
        image: "/static/images/cards/imposto-renda.jpeg",
    },
    {
        title: 'Abertura e regularização',
        subtitle: 'Para empresas',
        descritption: 'Elaboração e alteração de contratos sociais, análise de processos fiscalizatórios.',
        image: "/static/images/cards/abertura.webp",
    },
    {
        title: 'Assembleias gerais',
        subtitle: 'Para condomínios',
        descritption: 'Edital de convocação, análise de presentes e procurações, redação e registro de ata.',
        image: "/static/images/cards/assembleia.jpeg",
    },
    {
        title: 'Auditoria',
        subtitle: 'Para pessoas jurídicas e condomínios',
        descritption: 'Em nossas áreas de atuação.',
        image: "/static/images/cards/auditoria.webp",
    },
]
export const team = {
    count: 14,
    members: [
        {
            name: "Almir Reis",
            src: "/static/images/avatar/almir.jpg",
        },
        {
            name: "Eliana Montefusco",
            src: "/static/images/avatar/eliana.jpg",
        },
        {
            name: "Maria Rosa Fernandes",
            src: "/static/images/avatar/mrosa.jpg",
        },
    ],
}
export const website = {
    name: "Grupo Griffo",
    address: "https://www.grupogriffo.com",
    serviceDays: "De segunda a sexta-feira",
    openingHours: "Das 8h30 às 12h e das 14h às 17h30",
    phone: "(13) 3278-2830",
}