import * as React from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import {green} from "@mui/material/colors";

import {contacts} from "../data/Constant";

export default function WhatsAppList() {
    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            <nav aria-label="main mailbox folders">
                <List>
                    {contacts.map((contact) => (
                        <ListItem disablePadding>
                            <ListItemButton href={'https://wa.me/' + contact.number} target="_blank">
                                <ListItemIcon>
                                    <WhatsAppIcon sx={{ color: green[500] }}/>
                                </ListItemIcon>
                                <ListItemText primary={contact.name}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </nav>
        </Box>
    );
}