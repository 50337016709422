import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MailList from "./MailList";
import WhatsAppList from "./WhatsAppList";

import {website} from "../data/Constant";

export default function ContactAccordions() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            <Container className='content' maxWidth="md" disableGutters>
                <Typography variant="h3" align="center" color="text.primary" gutterBottom children='Contato' />
                <Accordion>
                    <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" >
                        <Typography sx={{width: '33%', flexShrink: 0}} children='Atendimento'/>
                        <Typography sx={{color: 'text.secondary'}} children={website.serviceDays} />
                    </AccordionSummary>
                </Accordion>

                <Accordion>
                    <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header" >
                        <Typography sx={{width: '33%', flexShrink: 0}} children='Horário' />
                        <Typography sx={{color: 'text.secondary'}} children={website.openingHours} />
                    </AccordionSummary>
                </Accordion>

                <Accordion>
                    <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header" >
                        <Typography sx={{width: '33%', flexShrink: 0}} children='Telefone' />
                        <Typography sx={{color: 'text.secondary'}} children={website.phone} />
                    </AccordionSummary>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel3-content" id="panel3-header" >
                        <Typography sx={{width: '33%', flexShrink: 0}} children='E-mail' />
                    </AccordionSummary>
                    <AccordionDetails>
                        <MailList/>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel4-content" id="panel4-header" >
                        <Typography sx={{width: '33%', flexShrink: 0}} children='WhatsApp' />
                    </AccordionSummary>
                    <AccordionDetails>
                        <WhatsAppList/>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </React.Fragment>
    );
}