import * as React from "react";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import {team} from "../data/Constant";

export default function Team() {
    return (
        <React.Fragment>
            <Container className='content' maxWidth="lg" disableGutters>
                <Typography children='Time' variant="h3" align="center" color="text.primary" gutterBottom />
                <AvatarGroup variant="circular" spacing='15' sx={{justifyContent: "center"}} max={4} total={team.count} >
                    {team.members.map((person) => (
                        <Avatar alt={person.name} src={person.src} sx={{width: 100, height: 100}} />
                    ))}
                </AvatarGroup>
            </Container>
        </React.Fragment>
    );
}