import * as React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function OfficeAddress() {
    return (
        <Container className='content' maxWidth="lg" >
            <Typography children='Endereço' variant="h3" align="center" color="text.primary" gutterBottom/>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d383.20755306880443!2d-46.309593961455086!3d-23.97086295915256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce024bf3569697%3A0xe5329d319b471491!2sGriffo%20Contabilidade!5e0!3m2!1spt-BR!2sbr!4v1682550019135!5m2!1spt-BR!2sbr"
                width="100%" height="450" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"/>
        </Container>
    )
}