import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Comments from "./components/Comments";
import ContactAccordions from "./components/ContactAccordions";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Nav from "./components/Nav";
import OfficeAddress from "./components/OfficeAddress";
import ServiceCards from "./components/ServiceCards";
import Team from "./components/Team";

import {heros} from "./data/Constant";

function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

shuffle(heros)

export default function App() {
    return (
        <React.Fragment>
            <CssBaseline/>
            <Nav/>
            <Box id="home"></Box>
            <Hero hero={heros[0]}/>
            <Box id="servicos"></Box>
            <ServiceCards/>
            <Hero hero={heros[1]}/>
            <Comments/>
            <Hero hero={heros[2]}/>
            <Team/>
            <Hero hero={heros[3]}/>
            <Box id="contato"></Box>
            <ContactAccordions/>
            <Hero hero={heros[4]}/>
            <OfficeAddress/>
            <Footer/>
        </React.Fragment>
    )
}


