import * as React from "react"

export default function SvgComponent() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
                flexBasis: '33.33%',
                maxHeight: 50,
                shapeRendering: 'geometricPrecision',
                textrendering: 'geometricPrecision',
                imageRendering: 'optimizeQuality',
                fillRule: 'evenodd',
                clipRule: 'evenodd'
            }}
            viewBox="0 0 2816 1000"
        >
            <defs>
                <style>

                </style>
            </defs>
            <g id="Camada_x0020_1">
                <path className="fil0"
                      d="M503 649c-19 87-58 153-119 200s-139 70-235 70c-65 0-115-9-149-27l25-116c44 28 91 42 142 42 50 0 92-13 126-39 33-26 55-62 65-108l8-39h-2c-45 53-101 80-168 80-61 0-105-21-132-63-28-42-34-98-19-168 16-78 50-141 100-188 50-46 108-70 173-70 59 0 99 23 119 67h2l12-55h140l-88 414zm-94-211c6-31 2-57-12-79-15-21-37-31-67-31-34 0-63 13-89 39-25 26-43 62-53 109-9 41-6 73 8 96 15 24 37 35 69 35s60-11 85-35c26-24 42-55 50-94l9-40z"/>
                <path id="1" className="fil0"
                      d="M1011 358c-15-9-35-14-59-14-32 0-60 12-83 35-24 24-40 56-49 97l-48 224H632l99-465h140l-18 87h2c36-63 85-95 146-95 17 0 29 1 37 5l-27 126z"/>
                <path id="2" className="fil0"
                      d="M1144 84c5-21 16-37 34-50 18-14 38-20 62-20 25 0 43 7 55 20s16 30 12 50c-4 21-16 38-34 51-18 14-39 21-63 21-23 0-41-7-54-21-12-14-16-31-12-51zm-121 616 100-465h139l-99 465h-140z"/>
                <path id="3" className="fil0"
                      d="M2071 470c16-77 53-137 110-181 56-44 125-66 205-66 77 0 134 22 170 65 37 43 47 101 32 174-17 76-53 137-109 182s-124 68-204 68c-78 0-136-22-173-65-36-44-47-103-31-177zm143-2c-20 93 9 139 87 139 75 0 123-47 143-143 20-91-8-136-84-136-37 0-68 12-94 36-25 25-43 59-52 104z"/>
                <path id="4" className="fil1"
                      d="M1732 116c-18-7-35-11-52-11-48 0-78 26-89 77l-11 53h110l-22 101h-109l-78 364h-140l77-364h-80l21-101h81l12-60c12-53 39-96 81-127 42-32 92-48 150-48 30 0 54 3 72 9l-23 107z"/>
                <path id="5" className="fil1"
                      d="M2106 116c-18-7-35-11-52-11-48 0-78 26-89 77l-11 53h110l-22 101h-109l-78 364h-140l77-364h-80l21-101h81l13-60c11-53 38-96 80-127 42-32 93-48 151-48 29 0 53 3 71 9l-23 107z"/>
            </g>
        </svg>
    )
}
